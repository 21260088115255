import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const pointinterrogatif = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Point interrogatif" />
    <h3 className='underline-title'>Point interrogatif</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Pourquoi m'a-t-on doté d'intelligence?<br />
        Pourquoi m'a-t-on fait monter à ce niveau? <br />
        Pourquoi a-t-on permis que je comprenne?<br />
        Pourquoi dois-je raisonner encore?<br />
      </p>
      <p>
        J'aurais aimé être comme celle-là:<br />
        La femme Mushi que je vois chaque matin <br />
        Sa houe à l'épaule allant à son jardin <br />
        Jardin plus haut que le Viet-nam <br />
        Jardin au-dessus de Palestine <br />
        Jardin surplombant l'Irlande du Nord<br />
      </p>
      <p>
        Ô simple femme ô femme ingnorante<br />
        Dis-moi ton secret <br />
        Ma R16 est moins fidèle que tes pieds déchaussés<br />
        Mon bic doré ne tient pas le coup comme ta houe<br />
        Tes trois sengi roulent moins vite que mes cent zaïres<br />
        Tes pieds intuitifs marchent dans le sens du vent <br />
        Mon bateau blindé navigue à contre-courant<br />
      </p>
      <p>
        Ô femme qui ignore qu'elle est heureuse <br />
        Ô heureuse femme!<br />
        Dis-moi  ton secret<br />
        Secret d'ignorance en bonheur<br />
        Ô simple femme <br />
        Dis-moi ton secret donc<br />
        Est-il criminel de n'être pas comme toi?<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default pointinterrogatif
